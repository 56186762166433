export const tablePagination = {
  data() {
    return {
      items: [], // Item list
      currentPage: 1, // Pagination
      totalItems: 0, // Pagination
      currentPageSize: 10, // Pagination
    }
  },
  methods: {
    activeRows() {
      let pageRowEnd = this.currentPageSize * this.currentPage
      let pageRowStart = this.currentPageSize * (this.currentPage - 1)
      if(pageRowEnd > this.totalItems) {
        return pageRowStart + (this.totalItems - pageRowStart)
      } else {
        return pageRowStart + this.currentPageSize
      }
    }
  }
};